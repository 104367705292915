<template>
  <div class="drive-detail">
    <div class="drive-detail-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <!-- 面包屑 -->
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>学驾服务</el-breadcrumb-item>
            <el-breadcrumb-item>驾校风采</el-breadcrumb-item>
            <el-breadcrumb-item>驾校详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 驾校区域 -->
        <div class="driving-demeanor" v-if="dataSheetDetail">
          <div class="demeanor">
            <div class="school-node">
              <div class="node-img" v-if="dataSheetDetail.schoolIntroduce">
                <img class="driving-img" :src="dataSheetDetail.schoolIntroduce.iconPath" />
              </div>
              <div class="node-info">
                <div class="info-title">{{ dataSheetDetail.name }}</div>
                <div class="info-score">
                  <el-rate
                    class="score-rate"
                    :value="dataSheetDetail.stars"
                    disabled
                    text-color="#ff9900"
                  ></el-rate>
                  <p class="score-nub">{{ dataSheetDetail.stars }}分</p>
                </div>
                <div class="info-address">
                  <img
                    class="address-icon"
                    src="../../assets/zlxcimgs/home_icon_location@2x.png"
                  />
                  <p class="address-text">
                    {{ dataSheetDetail.address }}
                  </p>
                </div>
                <div class="info-phone">
                  <img
                    class="phone-icon"
                    src="../../assets/zlxcimgs/home_icon_tell@2x.png"
                  />
                  <p class="phone-text">{{dataSheetDetail.phone}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="demeanor-btn">报名咨询</div> -->
        </div>

        <!-- 统计区域 -->
        <div class="ul">
          <div class="li">
            <div class="num_row">
              <div class="num">{{ carCoachObj.entryFee }}</div>
              <div class="unit">元</div>
            </div>
            <div class="lab">报名费</div>
          </div>
          <div class="li" v-if=" carCoachObj.classPrice">
            <div class="num_row">
              <div class="num">{{ carCoachObj.classPrice  }}  </div>
              <div class="unit">元</div>
            </div>
            <div class="lab">课时单价</div>
          </div>
          <div class="li">
            <div class="num_row">
              <div class="num">{{ carCoachObj.carTotal }}</div>
              <div class="unit">辆</div>
            </div>
            <div class="lab">车辆数</div>
          </div>
          <div class="li">
            <div class="num_row">
              <div class="num">{{ carCoachObj.coachTotal }}</div>
              <div class="unit">人</div>
            </div>
            <div class="lab">教练数</div>
          </div>
          <div class="li" v-if="carCoachObj.reserveTotal">
            <div class="num_row"> 
              <div class="num">{{ carCoachObj.reserveTotal }}</div>
              <div class="unit">人</div>
            </div>
            <div class="lab">预约数</div>
          </div>
        </div>
        <!-- 驾校详情 -->
        <div class="driving-detail">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="驾校简介" name="introduction" v-if="dataSheetDetail.schoolIntroduce">
              <div class="pane-title">
                <p class="title-circle"></p>
                <p class="title-text">驾校简介</p>
              </div>
              <div class="pane-substance">
                <p class="sub-text">{{dataSheetDetail.schoolIntroduce.introduce}}</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="场地" name="space">
              <div class="pane-title">
                <p class="title-circle"></p>
                <p class="title-text">场地</p>
              </div>
              <div
                class="pane-imgs"
              >
                <div class="imgs-context" v-for="(item, index) in dataSpaceList" :key="index">
                  <img class="driving-img" :src="item.iconPath" v-if="item.iconPath"/>
                </div>
              </div>
            </el-tab-pane>
            
            <el-tab-pane label="班型" name="classtype">
              <div class="pane-table">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column prop="carType" label="驾照类型" width="180">
                  </el-table-column>
                  <el-table-column prop="name" label="班型" width="180">
                  </el-table-column>
                  <el-table-column prop="totalPrice" label="套餐金额 (元)">
                  </el-table-column>
                  <el-table-column prop="remark" label="套餐属性">
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="地址电话" name="registration">
              <div class="pane-title">
                <p class="title-circle"></p>
                <p class="title-text">报名地址</p>
              </div>
              <div class="pane-address">
                <img
                  class="address-icon"
                  src="../../assets/zlxcimgs/home_icon_location@2x.png"
                />
                <p class="address-text">{{ dataSheetDetail.address }}</p>
              </div>
              <!-- <div class="pane-title" style="margin-top: 40px;">
                <p class="title-circle"></p>
                <p class="title-text">报名咨询</p>
              </div> -->
              <div class="pane-phone">
                <img
                  class="phone-icon"
                  src="../../assets/zlxcimgs/home_icon_tell@2x.png"
                />
                <p class="phone-text">{{ dataSheetDetail.phone }}</p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryGetSchoolDetailById,
  queryGetSiteBySchoolId,
  queryQueryList,
  getWebsiteSchoolDetailRespVO,
  queryTrainingClasss
} from "@/api/herbalMedicine";
import { formatTime } from "@/utils/common.js";

export default {
  name: "DriveDetail",
  data() {
    return {
      activeName: "introduction",
      tableData: [],
      // 驾校详情
      dataSheetDetail: {},
      // 场地图片列表
      dataSpaceList: [],
      // 驾校教练列表
      coachList: [],
      carCoachObj: {}, //统计信息
    };
  },
  created() {
    // 官网驾校信息
    this.getQueryGetSchoolDetailById();
    this.getWebsiteSchoolDetailRespVOFn()
  },
  methods: {
    handleClick(tab, event) {
      let curTab = tab.name;
      if (curTab == "space") {
        this.getQueryGetSiteBySchoolId();
      } else if (curTab == "coach") {
        this.getQueryQueryList();
      } else if (curTab == "classtype") {
        this.getQueryTrainingClasss();
      }
    },
    // 官网驾校信息
    getQueryGetSchoolDetailById() {
      queryGetSchoolDetailById({
        id: this.$route.query.id,
      }).then((res) => {
        this.dataSheetDetail = res.data;

        for (var item of this.dataSheetDetail) {
           item.starLevel = parseFloat(item.starLevel);
        }
      });
    },
    // 统计教练车辆信息
    async getWebsiteSchoolDetailRespVOFn() {
      const {data: res} = await getWebsiteSchoolDetailRespVO({ schoolId: this.$route.query.id,})
      console.log('++++++++++')
      this.carCoachObj = res
      console.log(res)
    },
    // 查询驾校场地图片列表
    getQueryGetSiteBySchoolId() {
      queryGetSiteBySchoolId({
        schoolId: this.$route.query.id,
      }).then((res) => {
        this.dataSpaceList = res.data.list;
      });
    },
    // 查询驾校教练员详情
    getQueryQueryList() {
      queryQueryList({
        trainingSchoolId: this.$route.query.id,
      }).then((res) => {
        this.coachList = res.data;
      });
    },
    // 根据驾校查询班型列表
    getQueryTrainingClasss() {
      queryTrainingClasss({
        schoolId: this.$route.query.id,
      }).then((res) => {
        this.tableData = res.data.list;
        for (var item of this.tableData ) {
            item.totalPrice = (item.totalAmount/100).toFixed(2);
        }
        
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>